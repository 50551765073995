import React, { useState } from "react"
import styles from "./winter-province-page-template.module.scss"
import Menu from "../Menu/MenuWinter"
import logo from "../../images/Logo Page.png"
import { Link } from "gatsby"
import ButtonMenu from "../ButtonMenu/ButtonMenu"
import gps from "../../images/Icon material-location-on.png"
import back from "../../images/back.png"

const WinterProvincePageTemplate = ({ province }) => {
  const [toggleMenu, setToggleMenu] = useState(true)

  const toggle = () => {
    if (toggleMenu) {
      setToggleMenu(false)
    } else {
      setToggleMenu(true)
    }
  }

  return (
    <>
      <Menu activeMenu={toggleMenu} />
      <div className={styles.imgfondocontainer}>
        {/* <AutoPlaySwipeableViews interval={5000} >
                    {province.images.map(function(image) {
                        return(
                            <img src={image} alt="" className={styles.imgfondo}/>
                        )
                    })}
                </AutoPlaySwipeableViews> */}
      </div>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.exploracontainer}>
            <Link to="/winter-sunsets#descubri" className={styles.exploratext}>
              EXPLORÁ
            </Link>
          </div>
          <div className={styles.centerLogo}>
            <img src={logo} alt="" className={styles.logo} />
          </div>
          <ButtonMenu activeMenu={toggleMenu} toggleMenu={toggle} />
          <div className={styles.participacontainer}>
            {/* <Link to="/viajesconconexion/experiencias" ><p className={styles.participatext}>PARTICIPÁ</p></Link> */}
          </div>
        </div>
        <div className={styles.contentexp}>
          <Link to="/winter-sunsets#descubri">
            <div className={styles.containervolver}>
              <img src={back} alt="" className={styles.imgback} />
              <h3 className={styles.textvolver}>VOLVER</h3>
            </div>
          </Link>
          <div className={styles.containeroverline}>
            <h1 className={styles.textoverline}>{province.overline}</h1>
          </div>
          <div className={styles.containertitle}>
            <h1 className={styles.texttitle}>{province.name}</h1>
          </div>
          <div className={styles.containergps}>
            <img src={gps} alt="" className={styles.gpsimg} />
            <h5 className={styles.textgps}>{province.location}</h5>
          </div>
          <div className={styles.containerdesc}>
            <p className={styles.description}>{province.description}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default WinterProvincePageTemplate
