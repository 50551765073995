import React from "react"
import WinterProvincePageTemplate from "../components/Winter/WinterProvincePageTemplate"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import "../styles/layout.module.css"

const WinterTemplate = props => {
  //Aca traemos el Hotel para cada pagina. Es un objeto con todos su atributos
  const province = props.data.allSitePage.edges[0].node.context

  return (
    <div>
      <SEO title={province.name} />
      <WinterProvincePageTemplate province={province} />
    </div>
  )
}

export default WinterTemplate

export const query = graphql`
  query WinterTemplateQuery($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          id
          context {
            id
            description
            images_provinces
            name
            overline
          }
        }
      }
    }
  }
`
